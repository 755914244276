import {
  type FooterV3Props,
  LinksCategoryId,
  type LinksData,
} from '@jouzen/ecom-components';
import { cloneDeep } from 'lodash';
import { useTranslations } from 'next-intl';
import { type DynamicConfig, useConfig } from 'statsig-react';

import useOuraGen4Enabled from '@/app/hooks/useOuraGen4Enabled';

import isPathMatchToPOP from '../utils/isPathMatchToPOP';
import type { NextT } from '../utils/localizeLinks';
import localizeLinks from '../utils/localizeLinks';
import useLinkModifierConfig from './useLinkModifierConfig';

interface FooterLinkProps extends Omit<DynamicConfig, 'value'> {
  value: {
    footerLinks: FooterV3Props['footerLinks'];
    legalLinks: FooterV3Props['legalLinks'];
  };
}

const useFooterConfig = (
  pathname: string,
): FooterLinkProps['value'] | undefined => {
  const t = useTranslations();
  const { config } = useConfig('global_footer_links');
  const footerConfigValue = config.value as FooterLinkProps['value'];
  const activeFooterConfig = Object.keys(footerConfigValue).length > 0;

  const gen4Enabled = useOuraGen4Enabled();
  // determine if current path is gen3 or OR4 POP, or neither
  const { isGen3POP, isPOP } = isPathMatchToPOP(pathname);

  // footer image link modifier config for POP
  const { isConfigActive, statsigLink } = useLinkModifierConfig(
    'pop_modified_footer_image_link',
    gen4Enabled,
    isGen3POP,
  );

  const statsigLinks = cloneDeep(footerConfigValue);

  // translate statsig link fields
  localizeLinks(statsigLinks.footerLinks, t as NextT);
  statsigLinks.legalLinks = statsigLinks.legalLinks?.map((link) => ({
    ...link,
    label: t(link.label),
  }));

  if (isConfigActive && isPOP && statsigLinks.footerLinks) {
    const index = statsigLinks.footerLinks.findIndex(
      (link) => link.id === LinksCategoryId.FooterCard,
    );
    if (typeof index !== 'undefined' && index !== -1) {
      const _statsigLink = statsigLink as LinksData;
      statsigLinks.footerLinks.splice(index, 1, _statsigLink);
    }
  }

  const footerCard = statsigLinks.footerLinks?.find(
    (footerLink) => footerLink.id === LinksCategoryId.FooterCard,
  );

  if (footerCard) {
    footerCard.title = t.rich(
      gen4Enabled ? 'business_contact_us_title' : 'footer_image_cta',
      {
        em: (chunks) => (
          <span className="ui-font-serif ui-italic ui-self-end">{chunks}</span>
        ),
      },
    ) as string;

    footerCard.link = {
      id: 'footer-card-link',
      ...footerCard.link,
      label: t(gen4Enabled ? 'for_business_link' : 'shop'),
      href: gen4Enabled ? '/business' : '/product/rings',
    };
  }

  if (!activeFooterConfig) {
    return undefined;
  }

  return statsigLinks;
};

export default useFooterConfig;
