import { useTranslations } from 'next-intl';

import ArtDirectionImage from '../Image/ArtDirectionImage';
import useOuraGen4Enabled from '@/app/hooks/useOuraGen4Enabled';

export const FooterImageComponent = (): JSX.Element => {
  const t = useTranslations();
  const gen4Enabled = useOuraGen4Enabled();

  return (
    <ArtDirectionImage
      alt={t('shop_rings')}
      className="h-56 w-full object-cover md:h-full md:w-auto xxl:min-w-full"
      fill
      sizes="(max-width: 767px) 100vw, 25vw"
      srcSet={[
        {
          src: gen4Enabled
            ? 'blue-sky/footer/buesiness-footer-cta-mobile'
            : 'blue-sky/footer-cta-image-mobile',
          media: '(max-width: 767px)',
        },
        {
          src: gen4Enabled
            ? 'blue-sky/footer/business-footer-cta'
            : 'blue-sky/footer-cta-image-desktop',
          media: '(min-width: 768px)',
        },
      ]}
    />
  );
};
