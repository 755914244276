import plugin from 'tailwindcss/plugin';

import { locales } from '../src/config';

const langPlugin = plugin((_plugin) => {
  locales.forEach((locale) => {
    _plugin.addVariant(locale, `html:lang(${locale}) &`);
  });
});

export default langPlugin;
