import { sharedConfigV3 } from '@jouzen/ecom-components';
import type { Config } from 'tailwindcss';

import clipPlugin from './tailwind-plugins/clip';
import langPlugin from './tailwind-plugins/lang';
import noScrollbarPlugin from './tailwind-plugins/no-scrollbar';
import noJsPlugin from './tailwind-plugins/nojs';

const config: Config = {
  content: ['./src/**/*.{ts,jsx,tsx,mdx}', './src/**/*.module.scss'],
  important: '.tailwind',
  plugins: [clipPlugin, langPlugin, noJsPlugin, noScrollbarPlugin],
  prefix: '',
  presets: [sharedConfigV3],
  theme: {
    extend: {
      animation: {
        scrollTeaserPillSlide: 'scrollTeaserPillSlide 3s ease infinite',
      },
      aspectRatio: {
        '4/3': '4 / 3',
        '5/4': '5 / 4',
        '3/2': '3 / 2',
      },
      colors: {
        // holdover colors for the wizard
        burntOrange: {
          DEFAULT: '#CC612C',
        },
      },
      keyframes: {
        scrollTeaserPillSlide: {
          '0%': {
            opacity: '0',
            transform: 'translate3d(-50%, 0, 0)',
          },
          '50%': {
            opacity: '1',
          },
          '100%': {
            opacity: '0',
            transform: 'translate3d(-50%, 32px, 0)',
          },
        },
      },
      lineHeight: {
        tighter: '1.1',
      },
    },
  },
};

export default config;
